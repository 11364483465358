import { ProductSliderProps } from 'components/Product/ProductsSlider/ProductsSlider';
import { IBlockDynamicProducts } from 'types/ContentfulTypes';
import RecentProductSlider from '../../../Product/RecentProductSlider/RecentProductSlider';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';

const DYNAMIC_PRODUCT_TYPES = {
  BUNDLES: 'bundles',
  RECENT_PRODUCTS: 'Recent products',
};

interface ContentfulBlockDynamicProductsProps extends ProductSliderProps {
  className?: string;
  content: IBlockDynamicProducts;
  hideTitle?: boolean;
}

const ContentfulBlockDynamicProducts = ({
  alwaysShowArrows = false,
  className,
  content,
  hideTitle,
  noMargin = true,
  slidesObject,
}: ContentfulBlockDynamicProductsProps) => {
  const { title, type } = content.fields;

  const sliderProps = {
    alwaysShowArrows,
    noMargin,
    slidesObject,
  };

  if (!content?.fields) {
    return null;
  }

  return (
    <ContentfulGroupingWrapper className={className}>
      {!hideTitle && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}
      <>{type === DYNAMIC_PRODUCT_TYPES.RECENT_PRODUCTS && <RecentProductSlider {...sliderProps} />}</>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulBlockDynamicProducts;
