import Loader from 'components/Layout/Loader/Loader';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { useRecentlyViewedProducts } from 'features';
import useRecentProductsFunctions from 'hooks/useRecentProductsFunctions';
import { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { ICON_NAMES } from '../../../constants/icon';
import ProductsSlider from '../ProductsSlider/ProductsSlider';
import { StyledDefaultIcon } from './RecentProductSlider.styled';

interface RecentProductSliderProps {
  className?: string;
  noMargin?: boolean;
  productCodesToExclude?: string[];
  slidesObject?: SlidesObject;
  title?: string;
  titleId?: string;
}

const RecentProductSlider = ({
  className,
  noMargin,
  productCodesToExclude,
  slidesObject,
  title,
  titleId = 'pdp_recently_viewed_title',
  ...props
}: RecentProductSliderProps) => {
  const { formatMessage } = useIntl();

  const [showLoader, setShowLoader] = useState(false);

  const { data: recentProductsResult, isFetching: isFetchingRecentProducts } = useRecentlyViewedProducts(
    productCodesToExclude ?? [],
  );

  const { clearRecentlyViewedProducts } = useRecentProductsFunctions();

  const clearList = () => {
    setShowLoader(true);
    setTimeout(() => {
      clearRecentlyViewedProducts();
    }, 200);
    setShowLoader(false);
  };

  const defaultButton = (
    <div
      aria-label={formatMessage({ id: 'recent_product_slider_delete_button' })}
      onClick={clearList}
      role="button"
      tabIndex={0}
    >
      <StyledDefaultIcon type="custom" name={ICON_NAMES.TRASH} size={125} />
    </div>
  );

  const titleValue = title || (titleId && formatMessage({ id: titleId }));

  if (!recentProductsResult?.products?.length) {
    return null;
  }

  if (showLoader) {
    return <Loader show />;
  }

  return (
    <ProductsSlider
      button={!!titleValue && defaultButton}
      isLoading={isFetchingRecentProducts}
      products={recentProductsResult.products}
      showAddToCart={false}
      className={className}
      slidesObject={slidesObject}
      title={titleValue}
      noMargin={noMargin}
      {...props}
    />
  );
};

export default memo(RecentProductSlider, isEqual);
